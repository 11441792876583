body {
  /* background: rgb(23, 61, 72);
  background: linear-gradient(
    149deg,
    rgba(23, 61, 72, 0.269642891336222) 4%,
    rgba(229, 219, 217, 1) 100%
  ); */

  background-color: whitesmoke;
  margin-top: 3.4rem;
  font-family: "Cabin", sans-serif;
  /* font-family: "Reddit Sans", serif; */
}

:root {
  --main-dark-color: #4c1130;
  --main-light-color: #f3e8db;
}

h3,
h6 {
  border-radius: 1em;
  background-color: var(--main-dark-color);
  color: var(--main-light-color);
  text-align: center;
  padding: 0.2em;
}

h3 {
  font-family: "Playfair Display", serif;
  font-family: "Zen Tokyo Zoo", serif;
  font-family: "Unbounded", serif;
  text-transform: uppercase;
  letter-spacing: 0.075rem;
  font-size: 1.4rem;
}

::selection {
  color: var(--main-light-color);
  background-color: var(--main-dark-color);
}
