.awards-box {
  width: 100%;
  height: 18vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  text-align: center;
}

.awards-header {
  font-family: "Italiana", serif;
  font-family: "Cormorant Garamond", serif;
  font-family: "Cairo Play", serif;
  font-family: "Playfair Display", serif;
  font-size: 2rem; /* Adjust size as needed */
  font-weight: bold;
  line-height: 3rem;
  letter-spacing: -0.025rem;
  color: #333; /* Dark text for contrast */
  text-shadow: 1px 4px 12px rgba(23, 61, 72, 0.377);
}

.highlighted-text {
  position: relative;
  color: var(--main-dark-color); /* Slightly darker to subtly stand out */
}

.highlighted-text::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -4px;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    90deg,
    var(--main-dark-color),
    var(--main-light-color)
  ); /* Subtle gradient underline */
  opacity: 0.7; /* Slight transparency for elegance */
  border-radius: 1px; /* Smooth edges */
}

.desktop-line-break {
  display: none; /* Default: hidden */
}

@media (min-width: 768px) {
  /* Breakpoint for desktop screens */
  .desktop-line-break {
    display: inline; /* Show the line break on larger screens */
  }
}

@media (max-width: 767px) {
  .awards-header {
    font-size: 1.3rem; /* Adjust size as needed */
    line-height: 2.5rem;
  }
}
